import React, { useEffect, useState } from "react";
import { BSBtn } from "traec-react/utils/bootstrap";
import WizardModal from "AppSrc/utils/modals/wizardModal";
import ReportRowErrorBoundary from "./error";
import { DocumentTitleTooltip, CurrentObject, DocumentNoReportCheckbox } from "./reportDocumentRow";
import Papa from "papaparse";
import { alertSuccess } from "traec-react/utils/sweetalert";
import { DataTableVertical } from "./dataTableVertical";
import { Hider } from "storybook-dashboard/utils";
import Im from "immutable";
import { fetchS3Object } from "./reportDocumentForm";

function LoadDataButton(props) {
  let { title, hide } = props;
  if (hide) {
    return null;
  }
  return (
    <button
      className="btn btn-sm btn-secondary pl-1 pr-1 m-0 p-0 float-right"
      onClick={(e) => {
        e.preventDefault();
        fetchS3Object(props);
      }}
    >
      {title}
    </button>
  );
}

const textToDataArray = (text) => {
  let results = Papa.parse(text, { header: true });
  return results.data;
};

export function LoadOrEditDataButton(props) {
  let { data, setData, doc, modalId, currentDocObject, readOnly, hide } = props;
  if (hide) {
    return null;
  }

  let docId = doc.get("uid");
  let current_url = currentDocObject ? currentDocObject.get("url") : null;

  let hasData = Im.isList(data) ? data.size > 0 : false;

  if (!hasData && current_url) {
    return (
      <LoadDataButton
        {...props}
        title={readOnly ? `View data` : `Edit data`}
        docId={docId}
        currentDocObject={currentDocObject}
        responseHandler={(response) =>
          response
            .text()
            .then((_text) => {
              setData(Im.fromJS(textToDataArray(_text)));
              $(`#${modalId}`).modal("show");
            })
            .catch((e) => {
              console.warn("ERROR LOADING DATA");
            })
        }
        failureHandler={(error) => {
          alertSuccess({
            title: "Error loading data",
            text: "There was an issue loading data for this form.  You will be presented with a new blank form to complete.  If you have issues or need to retrieve this data then please contact support.",
          });
          $(`#${modalId}`).modal("show");
        }}
      />
    );
  }

  let action = readOnly ? "View" : hasData ? `View & edit data (${data.size})` : "Add data";
  return (
    <BSBtn
      primaryOff
      text={`${action}`}
      onClick={(e) => $(`#${modalId}`).modal("show")}
      extra_className="btn-secondary pl-1 pr-1 m-0 p-0 float-right"
      noFloatRight={true}
    />
  );
}

export function ReportDocumentVerticalFormButton(props) {
  let { trackerId, commitId, path, doc, rowColor, indentLevel, readOnly, docStatus } = props;
  let [data, setData] = useState(Im.List());

  if (!doc) {
    return null;
  }

  let docId = doc.get("uid");

  let formDetails = doc.getIn(["meta_json", "input_details"]) || Im.Map();
  let formTemplate = doc.getIn(["meta_json", "input_details", "file_template"]) || Im.Map();
  let formFields = formDetails.get("fields");

  let modalId = `${doc.get("uid")}-form`;
  let modalTitle = formDetails.get("name");

  let noReport = docStatus?.getIn(["status", "name"]) == "Not for Submission";

  let assignments = doc.getInPath("meta_json.assignments");
  let hideNA = doc.getInPath("meta_json.hideNA") == true;

  return (
    <ReportRowErrorBoundary>
      <tr style={{ backgroundColor: rowColor }}>
        <td className="border-0">
          <DocumentTitleTooltip doc={doc} indentLevel={indentLevel} />
        </td>

        <td colSpan={3} className="border-0">
          <WizardModal
            id={modalId}
            title={modalTitle}
            fullWidth={true}
            body={
              <DataTableVertical
                readOnly={readOnly}
                fields={formFields}
                formName={modalTitle}
                data={data}
                setData={setData}
                trackerId={trackerId}
                commitId={commitId}
                doc={doc}
                path={path}
                formTemplate={formTemplate}
                assignments={assignments}
                modalId={modalId}
                closeModal={() => {
                  console.log("Closing DataTable modal", modalId);
                  $(`#${modalId}`).modal("hide");
                }}
              />
            }
          />
          <CurrentObject {...props} docId={docId} hide={noReport} noFileMessage=" " />
          <LoadOrEditDataButton {...props} data={data} setData={setData} modalId={modalId} hide={noReport} />
        </td>

        <td className="text-center border-0">
          <Hider hide={hideNA}>
            <DocumentNoReportCheckbox {...props} />
          </Hider>
        </td>
        <td className="border-0">{/* Admin dropdown here */}</td>
      </tr>
    </ReportRowErrorBoundary>
  );
}
