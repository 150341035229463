import React, { useEffect, useState } from "react";
import { parseNumber } from "@brightspace-ui/intl/lib/number.js";
import { fetcher } from "storybook-dashboard/utils/fetching";

import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";
import Moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { getConversionFactor, MetricAlerts, RequiredStar } from "../metrics/metricRow";

import RowErrorBoundary, { MiniErrorBoundary, CellSpanErrorBoundary } from "./error";
import { SubNodes } from "./node";

import SearchChildRow from "./search";
import SelectChildRow from "./selectChildren";
import SelectTwinChildRow from "./selectTwinChildren";
import { setAndShowModal, clearModal } from "AppSrc/utils/modal";

import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "traec-react/errors/handleError";

import ChartJSWrapper from "AppSrc/charts/chartjs";
import { getAverage, check100PercentDifference } from "storybook-dashboard/utils/index";
import { roundDecimals } from "storybook-dashboard/dashboard/charts/utils";

import { useReportContext } from "./context";
import { useFullIds } from "../utils/hooks";
import Im from "immutable";

function DisabledInput({ value }) {
  return (
    <input
      type="number"
      disabled={true}
      className={`form-control-plaintext m-0 p-0`}
      name={"value"}
      value={value === null ? "" : value}
      style={{
        backgroundColor: "#e9ecef",
      }}
    />
  );
}

export function ReportNoInputCheckbox({ hide, calculated, disableInputs, value, path }) {
  if (hide) {
    return null;
  }
  let isDisabled = disableInputs || calculated;

  let { setInputValue } = useReportContext();

  return (
    <td className="text-center border-0">
      <input
        type="checkbox"
        disabled={isDisabled}
        className={`m-0 p-0`}
        defaultChecked={value?.getInPath("meta_json.noReport")}
        onChange={(e) => {
          setInputValue(
            Im.Map({
              _path: path,
              value: null,
              meta_json: { noReport: e.target.checked, disableValueInput: e.target.checked },
            })
          );
          const input = document.getElementById(path);
          input.value = null;
        }}
        name={"noreport"}
      />
    </td>
  );
}

export function ReportValueInputCell(props) {
  let { setInputValue, isCalculated } = useReportContext();

  let { hide, isValid, disableInputs, disableValueInput, value: valueObject, score, path } = props;

  if (hide) return null;

  let value = valueObject?.get("value");
  let [tmpValue, setTmpValue] = useState(value == null ? "" : value);
  const calculated = isCalculated(score);

  const [largeDifferenceReported, setLargeDifferenceReported] = useState(false);
  const [values, setValues] = useState(Im.List());
  const [historicValues, setHistoricValues] = useState(Im.List());
  const [historicAverage, setHistoricAverage] = useState(0);
  const [isInFocus, setIsInFocus] = useState(false);

  const baseMetricId = score.getIn(["metric", "uid"]);
  const { trackerId, commitId } = useFullIds();

  useEffect(() => {
    if (isInFocus)
      fetchInputHistory({
        baseMetricId,
        commitId,
        trackerId,
        setValues,
      });
  }, [isInFocus]);

  useEffect(() => {
    setHistoricValues(
      values
        ?.map((report) => report?.get("value"))
        ?.filter((val) => typeof val == "number")
        ?.toJS()
    );
  }, [values]);

  useEffect(() => setHistoricAverage(getAverage(historicValues)), [historicValues]);

  const handleFocus = () => {
    setIsInFocus(true);
    setTmpValue(value);
  };

  let invalidFeedback = null;

  if (!isValid && isValid !== undefined) {
    invalidFeedback = <div className="invalid-feedback">Number required</div>;
  }

  const currentValueVarianceFromHistoric = (e) => {
    e.preventDefault();
    //  Check current value is 100% larger or smaller than historic average
    if (
      e.target.value &&
      historicValues?.length > 0 &&
      e.target.value !== 0 &&
      !(historicAverage < 20 && e.target.value < 20) &&
      check100PercentDifference(historicAverage, parseNumber(e.target.value))
    ) {
      setLargeDifferenceReported(true);
    } else {
      setLargeDifferenceReported(false);
    }
  };

  const valueVarianceWarning = (
    <Tooltip
      style={{ cursor: "pointer" }}
      position="bottom"
      html={
        <div>
          <u>Alert</u>: this value is significantly different to the average:{" "}
          <u>{`${roundDecimals(historicAverage)}`}</u> of the previous
          {` ${historicValues?.length}`} entries.
          <br /> Please check before submitting.
        </div>
      }
    >
      <Octicon name="alert" className="text-warning mt-1 mr-1" />
      <span className="badge badge-warning m-0">Check</span>
    </Tooltip>
  );

  const onBlur = (e) => {
    let newValue = parseNumber(e.target.value);
    if (e.target.value === "" || isNaN(newValue)) {
      newValue = null;
    }
    if (newValue != value) {
      setInputValue(Im.Map({ _path: path, value: newValue }));
    }
    currentValueVarianceFromHistoric(e);
  };

  if (calculated || disableInputs || (disableInputs && !value)) {
    return (
      <td className="border-0">
        <DisabledInput value={value} />
      </td>
    );
  }

  const handleChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9.,]/g, "");
    setTmpValue(inputValue);
  };

  let _value = isInFocus ? tmpValue : value;

  return (
    <td className="border-0">
      <input
        disabled={calculated || disableValueInput || score.noReport}
        style={{ height: "auto", display: "inline-block" }}
        className={`form-control m-0 p-0 ${invalidFeedback ? "is-invalid" : null} ${
          largeDifferenceReported ? "border-warning" : null
        }`}
        name={"value"}
        value={_value == null ? "" : _value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={handleFocus}
        id={path}
      />
      {invalidFeedback}
      {largeDifferenceReported ? valueVarianceWarning : null}
    </td>
  );
}

const dateToStr = (date, addDays) => {
  let _date = Moment(date);
  _date = _date && addDays ? _date.add(addDays, "days") : _date;
  return _date ? _date.format("Do MMM YY") : "";
};

export function ReportPeriodText({ isRequiredPeriodically, preamble }) {
  if (!isRequiredPeriodically) {
    return null;
  }
  let { from_date, dueDate } = isRequiredPeriodically;
  let _preamble = preamble || "For period from";
  return `${_preamble} ${dateToStr(from_date)} to ${dateToStr(dueDate, -1)}`;
}

function MetricPlaceholderText({ score, isRequiredPeriodically }) {
  if (!score) {
    return "";
  }
  let freqText = "";
  let placeholderText = score.getIn(["meta_json", "placeholder"]) || "";
  if (isRequiredPeriodically) {
    freqText = ReportPeriodText({ isRequiredPeriodically }) || "";
    freqText = placeholderText ? ` (${freqText})` : freqText;
  }
  return placeholderText + freqText;
}

export function ReportCommentCell(props) {
  let { hide, score, disableInputs, disableValueInput, value: valueObject, path, colSpan } = props;
  let comment = valueObject?.get("comment") || "";
  const { isCalculated, setInputValue } = useReportContext();
  let [tmpComment, setTmpComment] = useState(comment);

  if (hide) return null;

  const onBlur = (e) => {
    if (tmpComment != comment) {
      setInputValue(Im.Map({ _path: path, comment: tmpComment }));
    }
  };

  const calculated = isCalculated(score);
  let disabled = calculated || disableInputs || disableValueInput;
  let CommentInputComponent = calculated ? "input" : TextareaAutosize;
  let placeholder = calculated ? "Calculated from sub-metrics" : MetricPlaceholderText(props) || "";

  return (
    <td colSpan={colSpan || 1} className="border-0">
      <CommentInputComponent
        type="text"
        className={`form-control-plaintext m-0 p-0`}
        name={"comment"}
        disabled={disabled}
        value={calculated ? "" : tmpComment}
        placeholder={placeholder}
        onChange={(e) => setTmpComment(e.target.value)}
        onBlur={onBlur}
      />
    </td>
  );
}

export function ToggleState({ id, labelText, checkedState, state, onChange, disableInputs }) {
  return (
    <React.Fragment>
      <input id={id} name={`${id}-state-d`} type="radio" checked={state === checkedState} readOnly={true} />
      <label
        htmlFor={id}
        onClick={(e) => {
          if (!disableInputs) onChange(checkedState);
        }}
        className={`text-nowrap flex-fill rounded-pill ${
          state === checkedState && disableInputs
            ? "bg-light text-secondary"
            : state === checkedState
            ? "bg-white text-dark border border-dark"
            : ""
        }`}
      >
        {labelText}
      </label>
    </React.Fragment>
  );
}

// This returns the state for a BooleanInput not a boolean from a state
const getBooleanState = (value) => {
  return [null, undefined].includes(value) ? null : value > 0;
};

const stateToValue = (state) => {
  return [null, undefined].includes(state) ? null : state == false ? 0 : 1;
};

function BooleanToggleCell(props) {
  const { colSpan, value, handleInputChange, disableInputs } = props;
  const [state, setState] = useState(getBooleanState(value?.get("value")));

  // Generate a uuid for the on/na/off ids
  let uid = uuidv4();
  let _props = {
    state,
    onChange: (e) => {
      setState(e);
      handleInputChange({
        target: {
          value: stateToValue(e),
        },
      });
    },
    ...props,
  };

  return (
    <td colSpan={colSpan || 1} className="border-0">
      <div
        className={`d-flex flex-column flex-md-row switch-toggle text-white rounded-pill border ${
          disableInputs ? "bg-secondary border-secondary" : "bg-dark border-dark"
        }`}
      >
        <ToggleState id={`${uid}-on`} labelText="Yes" checkedState={true} {..._props} />
        <ToggleState id={`${uid}-na`} labelText="N/A" checkedState={null} {..._props} />
        <ToggleState id={`${uid}-off`} labelText="No" checkedState={false} {..._props} />
      </div>
    </td>
  );
}

const rowComponentMap = {
  standard: ValueCommentRow,
  boolean: ValueCommentRow,
  searchable_children: SearchChildrenRow,
  select_children: SelectChildrenRow,
  selectable_twin_children: SelectTwinChildrenRow,
};

const getRowComponent = (score) => {
  let inputType = score.getIn(["meta_json", "input_type"]) || "standard";
  return rowComponentMap[inputType] || ValueCommentRow;
};

function MetricName(props) {
  let { tree, score, indentLevel, hasChildren, isCollapsed, onCollapseClick } = props;
  let name = score.getIn(["metric", "name"]);
  let treeId = tree.get("uid");

  let nameComponent = hasChildren ? (
    <a data-toggle="collapse" href={`#${treeId}`} aria-expanded={"false"} onClick={onCollapseClick}>
      <Octicon className="expand_caret" name={isCollapsed ? "triangle-right" : "triangle-down"} />
      {name}
    </a>
  ) : (
    <React.Fragment>
      {/* <Octicon name={"primitive-dot"} /> */}
      {name}
    </React.Fragment>
  );

  return <span style={{ margin: "0", marginLeft: `${indentLevel * 1.5}em` }}>{nameComponent}</span>;
}

function MetricTooltip({ score }) {
  let descr = score.getIn(["metric", "description"]);
  if (!descr) {
    return null;
  }
  return (
    <Tooltip
      animateFill={false}
      html={
        <div className="text-left">
          <div dangerouslySetInnerHTML={{ __html: descr.trim() }} />
        </div>
      }
    >
      <Octicon name="info" className="ml-2" />
    </Tooltip>
  );
}

function NumSubDocs({ subDocs }) {
  let docNumStr = subDocs ? (subDocs.length ? `(${subDocs.length} docs) | ` : "") : "";
  if (!docNumStr) {
    return null;
  }
  return <span>{docNumStr}</span>;
}

const showConversionFactors = (e, props) => {
  e.preventDefault();
  let text = "";
  let { conversionFactor } = props;
  if (!conversionFactor) {
    alert("No conversion factor found");
  }

  let fromMetric = conversionFactor.getIn(["metric", "name"]);
  let fromUnit = conversionFactor.getIn(["metric", "unit"]);
  let toUnit = conversionFactor.get("toUnit");
  let factor = conversionFactor.get("factor");

  text += `${fromMetric}:\n`;
  text += `${fromUnit} -> ${toUnit} = ${factor}\n\n`;
  alert(text);
};

const showChildConversions = (e, props) => {
  e.preventDefault();
  let { childConversions: conversions } = props;
  let text = "Conversions from sub-metrics:\n\n";
  for (let conversion of conversions) {
    text += `${conversion.getIn(["fromMetric", "name"])}:\n`;
    text += `${conversion.get("inputValue")} ${conversion.getIn(["fromMetric", "unit"])} -> ${conversion.get(
      "convertedValue"
    )} ${conversion.get("toUnit")}\n`;
    text += `(conversion factor: ${conversion.get("conversionFactor")})\n\n`;
  }
  alert(text);
};

const getDropDownLinks = (props) => {
  let { parentScore, hasChildren, calculated } = props;

  let thisItems = [];
  // Give the user the option to input manually
  if (calculated) {
    thisItems.push({
      name: "Show derivation from sub-metrics",
      onClick: (e) => showChildConversions(e, props),
    });
  }

  // Show conversion factors to parent
  if (parentScore) {
    thisItems.push({
      name: "Show conversion factors to parent",
      onClick: (e) => showConversionFactors(e, props),
    });
  }

  return thisItems;
};

function StateSaved({ isSaved }) {
  if (!isSaved) {
    return null;
  }
  return <Octicon name="check" />;
}

const fetchInputHistory = ({ baseMetricId, commitId, trackerId, setValues }) => {
  let formData = new FormData();
  formData.append("type", "INPUT_VALUE_HISTORY");
  formData.append(
    "payload",
    JSON.stringify({
      num_items: 6,
      commitId,
      baseMetricId,
    })
  );

  fetcher(`/api/tracker/${trackerId}/dispatch/`, "POST", formData).then(({ payload, errors }) => {
    let data = payload?.get("payload");
    // error handling if an error is received, it will be an object and .reverse() will cause an error.
    if (errors) {
      console.log("Error in response from INPUT_VALUE_HISTORY", data?.toJS());
      setValues(Im.List());
    } else {
      console.log("Got response from INPUT_VALUE_HISTORY", data?.toJS());
      setValues(data.reverse());
    }
  });
};

function MetricValueHistory(props) {
  let { unit, baseMetricId, commitId, trackerId } = props;

  let [values, setValues] = useState(Im.List());

  console.log("Showing MetricValueHistory with", trackerId, commitId, baseMetricId);

  useEffect(() => {
    fetchInputHistory({ ...props, setValues });
  }, []);

  if (!values.size) {
    return null;
  }

  let data = {
    labels: values.map((i) => i.get("period_str")).toJS(),
    datasets: [
      {
        data: values.map((i) => i.get("value")).toJS(),
        label: "",
        backgroundColor: "#b3d9ff",
      },
    ],
  };

  let chartData = {
    type: "bar",
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: unit,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    data,
  };

  console.log("Rendering MetricValueHistory with data", chartData);

  return (
    <ErrorBoundary>
      <div>
        <ChartJSWrapper chartData={chartData} />
      </div>
    </ErrorBoundary>
  );
}

function PastInputModal({ score }) {
  let { trackerId, commitId } = useFullIds();
  let MODAL_ID = "ReportMetricHistoryModal-001";
  let baseMetricId = score?.getIn(["metric", "uid"]);
  return (
    <span
      className="mt-1 p-0 btn btn-sm"
      style={{ cursor: "pointer" }}
      onClick={() => {
        clearModal(MODAL_ID);
        setTimeout(() => {
          setAndShowModal(MODAL_ID, {
            title: `Previous data for: ${score?.getIn(["metric", "name"])}`,
            body: (
              <MetricValueHistory
                unit={score?.getIn(["metric", "unit"])}
                baseMetricId={baseMetricId}
                commitId={commitId}
                trackerId={trackerId}
              />
            ),
          });
        }, 250);
      }}
    >
      <Octicon name="graph" />
    </span>
  );
}

export function RowAdminPanel(props) {
  let { subDocs, isSaved, conversionFactor, disableInputs } = props;
  if (disableInputs) {
    return (
      <div className="m-0 p-0 text-nowrap d-flex align-items-center justify-content-center">
        <PastInputModal {...props} />
      </div>
    );
  }
  let dropDownLinks = getDropDownLinks(props);
  return (
    <div className="m-0 p-0 text-nowrap d-flex align-items-center justify-content-center">
      <NumSubDocs subDocs={subDocs} />
      <StateSaved isSaved={isSaved} />
      <PastInputModal {...props} />
      {dropDownLinks?.length && isSaved ? "|" : null} <MetricAlerts conversionFactor={conversionFactor} />
    </div>
  );
}

export function NotRequiredMsg({ dueDate, freq_num, freq_unit }) {
  return (
    <span>
      Due every {freq_num} {freq_unit}. Next due: {dueDate.format("Do MMM YY")}
    </span>
  );
}

function NotRequiredCells(props) {
  return (
    <td colSpan={4} style={{ verticalAlign: "middle" }} className="border-0">
      <NotRequiredMsg {...props} />
    </td>
  );
}

export const isNotInFrequency = ({ value, isRequiredPeriodically }) => {
  return !value && isRequiredPeriodically && !isRequiredPeriodically.dueThisReport;
};

function RowInputCells(props) {
  let { score, isRequiredPeriodically, ValueInputComponent, valueColSpan, commentColSpan, hideUnits, hideNoReport } =
    props;

  ValueInputComponent = ValueInputComponent || ReportValueInputCell;

  if (isNotInFrequency(props)) {
    return <NotRequiredCells {...isRequiredPeriodically} />;
  }

  return (
    <CellSpanErrorBoundary colSpan={4}>
      <MetricUnitCell score={score} hide={hideUnits} />
      <ValueInputComponent {...props} colSpan={valueColSpan || 1} />
      <ReportCommentCell {...props} colSpan={commentColSpan || 1} />
      <ReportNoInputCheckbox {...props} hide={hideNoReport} />
    </CellSpanErrorBoundary>
  );
}

export function MetricNameDescriptionCell(props) {
  let { score } = props;
  return (
    <td className="border-0">
      <MetricName {...props} />
      <RequiredStar score={score} />
      <MetricTooltip {...props} />
    </td>
  );
}

function MetricUnitCell({ score, hide }) {
  if (hide) {
    return null;
  }
  return <td className="border-0">{score.getIn(["metric", "unit"])}</td>;
}

function ValueCommentRow(props) {
  let { score, rowStyle, calculated, noReport, exclSubNodes, value } = props;

  let highlight = (score.get("period") || -1) > 0;
  let _noReport = calculated ? false : noReport;

  let inputType = score.getIn(["meta_json", "input_type"]) || "standard";
  let isBool = inputType == "boolean";
  let ValueInputComponent = isBool ? BooleanToggleCell : ReportValueInputCell;

  if (exclSubNodes == undefined && score.getIn(["meta_json", "hideChildrenIfNullOrZero"])) {
    exclSubNodes = value?.get("value") ? exclSubNodes : true;
  }

  // As per ASS-1457 - hide input cells if requested in meta_json
  let hideInputCells = score?.getIn(["meta_json", "hideInputCells"]);
  if (hideInputCells) {
    return (
      <React.Fragment>
        <tr style={rowStyle?.toJS()} className={highlight ? "font-weight-bold" : ""}>
          <MetricNameDescriptionCell {...props} />
          <CellSpanErrorBoundary colSpan={5}>
            <td className="border-0"></td>
            <td className="border-0"></td>
            <td className="border-0"></td>
            <td className="border-0"></td>
            <td className="border-0"></td>
          </CellSpanErrorBoundary>
        </tr>
        <RowErrorBoundary>
          <SubNodes {...props} parentNoReport={_noReport} hide={exclSubNodes} />
        </RowErrorBoundary>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <tr style={rowStyle?.toJS()} className={highlight ? "font-weight-bold" : ""}>
        <MetricNameDescriptionCell {...props} />
        <RowInputCells
          {...props}
          ValueInputComponent={ValueInputComponent}
          valueColSpan={isBool ? 2 : 1}
          commentColSpan={isBool ? 2 : 1}
          hideUnits={isBool}
          hideNoReport={isBool}
        />
        <td className="border-0 p-0 m-0 d-flex justify-content-center">
          <MiniErrorBoundary>
            <RowAdminPanel {...props} />
          </MiniErrorBoundary>
        </td>
      </tr>
      <RowErrorBoundary>
        <SubNodes {...props} parentNoReport={_noReport} hide={exclSubNodes || isNotInFrequency(props)} />
      </RowErrorBoundary>
    </React.Fragment>
  );
}

function SearchChildrenRow(props) {
  return (
    <RowErrorBoundary>
      <RowErrorBoundary>
        <ValueCommentRow {...props} exclSubNodes={true} />
      </RowErrorBoundary>
      <RowErrorBoundary>
        <SearchChildRow {...props} hide={isNotInFrequency(props)} />
      </RowErrorBoundary>
    </RowErrorBoundary>
  );
}

function SelectChildrenRow(props) {
  let { rowStyle } = props;
  return (
    <RowErrorBoundary>
      <RowErrorBoundary>
        <tr style={rowStyle?.toJS()} className={""}>
          <MetricNameDescriptionCell {...props} />
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
        </tr>
      </RowErrorBoundary>
      <RowErrorBoundary>
        <SelectChildRow {...props} hide={isNotInFrequency(props)} />
      </RowErrorBoundary>
    </RowErrorBoundary>
  );
}

function SelectTwinChildrenRow(props) {
  return (
    <RowErrorBoundary>
      <RowErrorBoundary>
        <ValueCommentRow {...props} rowStyle={Im.Map({ display: "none" })} exclSubNodes={true} />
      </RowErrorBoundary>
      <RowErrorBoundary>
        <SelectTwinChildRow {...props} />
      </RowErrorBoundary>
    </RowErrorBoundary>
  );
}

export default function ReportScoreRow(props) {
  let { getNode, getParentPath, conversionFactorMap, inputValueMap, isCalculated } = useReportContext();
  let { indentLevel, disableInputs, path, currentReportingPeriod, InputComponent, parentPath, rowStyle } = props;

  if (parentPath) {
    console.log("ReportScoreRow", path, parentPath);
  }

  // Get the node details, parent and related conversion factor
  let score = getNode(path);
  parentPath = parentPath || getParentPath(path);
  let parentNode = getNode(parentPath);
  let parentScore = parentNode?.get("_type") == "metricscore" ? parentNode : null;
  let conversionFactor = getConversionFactor({
    score,
    parentScore,
    convFactorDetails: { convFactorMap: conversionFactorMap },
  });

  // Is this input required in this reporting period
  let isRequiredPeriodically = isRequiredOnFreq(score, currentReportingPeriod);

  // Get the value that has been entered for this metric
  let baseMetricId = score.getIn(["metric", "uid"]);
  let value = inputValueMap.get(baseMetricId);
  let comment = value?.get("comment");

  /**********************
   ACTIONS
   **********************/

  const noReport = value?.getInPath("meta_json.noReport", false) || false;
  const disableValueInput = value?.getInPath("meta_json.disableValueInput", false) || false;

  let isHidden = score.getIn(["meta_json", "hidden"]);
  const calculated = isCalculated(score);
  rowStyle = rowStyle || Im.fromJS({});

  if (isHidden) {
    rowStyle = rowStyle.set("display", "none");
  } else {
    rowStyle = rowStyle.set("display", "");
  }

  // Get the component to render for this row - and extra props to pass to it (from state)
  let RowComponent = InputComponent || getRowComponent(score);
  let _props = {
    ...props,
    indentLevel: isHidden ? indentLevel - 1 : indentLevel,
    calculated,
    noReport,
    rowStyle,
    disableInputs,
    disableValueInput,
    value,
    comment,
    score,
    conversionFactor,
    isRequiredPeriodically,
  };

  return (
    <RowErrorBoundary>
      <RowComponent {..._props} />
    </RowErrorBoundary>
  );
}

export const isRequiredInPeriod = (score, reportingPeriod) => {
  let isRequired = score.get("required", false);
  if (!isRequired) {
    return false;
  }
  let isRequiredPeriodically = isRequiredOnFreq(score, reportingPeriod);
  if (!isRequiredPeriodically) {
    return isRequired;
  }
  let { dueThisReport } = isRequiredPeriodically;
  if (dueThisReport) {
    return true;
  }
  return false;
};

const isDueData = (score, prev_date, cur_date, isDue) => {
  let data = {
    dueThisReport: isDue,
    from_date: Moment(prev_date.toISOString()),
    dueDate: Moment(cur_date.toISOString()),
    freq_unit: score.get("freq_unit"),
    freq_num: score.get("freq_num"),
  };
  return data;
};

export const isRequiredOnFreq = (score, currentReportingPeriod) => {
  if (!score || !currentReportingPeriod) {
    return null;
  }
  let freq_unit = score.get("freq_unit");
  let freq_num = score.get("freq_num");
  let from_date = score.get("from_date");
  if (!freq_unit | !freq_num | !from_date) {
    return null;
  }
  // Convert the quarters into 3* months
  if (freq_unit === "quarters") {
    freq_unit = "months";
    freq_num = freq_num * 3;
  }
  // Start at from_date and step up until we are past the current reporting period (or now)
  let cur_date = Moment(from_date).startOf("day");
  let prev_date = Moment(cur_date);
  let startDate = Moment(currentReportingPeriod.get("startDate"));
  let endDate = Moment(currentReportingPeriod.get("endDate"));

  // Look forwards 100 periods from the start
  for (let i = 0; i < 100; i++) {
    let _date = Moment(cur_date).add(-7, "days");
    if (_date.isAfter(endDate)) {
      return isDueData(score, prev_date, cur_date, false);
    }
    if (_date.isBetween(startDate, endDate)) {
      return isDueData(score, prev_date, cur_date, true);
    }
    prev_date = Moment(cur_date);
    cur_date = cur_date.add(freq_num, freq_unit);
  }
  return isDueData(score, prev_date, cur_date, false);
};

export const nestBaseMetric = (state, score) => {
  if (!state || !score) {
    return null;
  }
  // If the baseMetric is not already nested in the score then do it now
  let baseMetricId = score.getIn(["metric", "uid"]);
  if (!baseMetricId) {
    baseMetricId = score.get("metric");
    if (baseMetricId) {
      return score.set("metric", state.getIn([`entities`, `baseMetrics`, `byId`, `${baseMetricId}`]));
    }
  }
  return score;
};
