import React, { useState, useEffect, useRef } from "react";
import Octicon from "react-octicon";
import Traec from "traec";

import { confirmDelete } from "traec-react/utils/sweetalert";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import { documentFields } from "./forms";

import { Tooltip } from "react-tippy";

import { DocumentMetaModal } from "AppSrc/forms/docmeta";
import { useProjectContext } from "../context";
import { Modal, useModal } from "storybook-dashboard/components/modal";
import { doFetch, fetcher } from "storybook-dashboard/utils/fetching";

import { mergeNodes } from "./node";
import { useFullIds } from "../utils/hooks";

export const setNodeMeta = ({ trackerId, refId, commitId, path, meta_json, setPending, hideModal, fetches }) => {
  fetcher(`/api/tracker/${trackerId}/commit/${commitId}/node/${path}/`, "PUT", {
    type: "document",
    node: {
      document: {
        meta_json,
      },
    },
  })
    .then(({ payload }) => {
      let { data, mutate } = fetches.nodes;
      mutate(mergeNodes(data, payload));
      setPending(false);
      if (hideModal) hideModal();
    })
    .catch(() => setPending(false));
};

const deleteNode = ({ path, fetches }) => {
  confirmDelete({
    text: `This will delete the Document.  Are you sure you would like to proceed?`,
    onConfirm: () => {
      let { url, mutate, data } = fetches.nodes;
      doFetch(`${url}/${path}/`, "DELETE").then((response) =>
        mutate(data?.filter((i) => !i.get("path")?.startsWith(path)))
      );
    },
  });
};

function EditDescriptionModal(props) {
  let { description, trackerId, refId, commitId, fetches } = props;
  let { hideModal } = useModal();

  let { data, mutate } = fetches.nodes;

  let pathId = description.get("_path");

  let fetch = new Traec.Fetch("tracker_node", "put", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      path: pathId,
      type: "description",
      node: {
        description: {
          title: data.title,
          text: data.description,
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Edit Upload">
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(documentFields).toJS()}
        initFields={description ? description.set("description", description.get("text") || "") : Traec.Im.Map()}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

function SetAssignmentInput(props) {
  let { trackerId, refId, commitId, document, hideModal } = props;
  // Pretty-print the initial JSON with indent=4
  const inputEl = useRef(null);
  let initValue = JSON.stringify(document.getInPath("meta_json.assignments") || [], undefined, 4);
  let [value, setValue] = useState(initValue);
  let [isValid, setIsValid] = useState(true);
  let [pending, setPending] = useState(false);
  let [charsToCursor, setCharsToCursor] = useState((initValue || "").length);

  const setCursorPos = (_value) => {
    if (!isValid) {
      return;
    }
    let _textArea = inputEl.current;
    console.log("Setting cursor position", _value.length, charsToCursor);
    // Set the cursor position to the same character-position (ignoring whitespaces)
    let nChars = 0;
    for (let i = 0; i < _value.length; i++) {
      if (_value[i] !== " ") {
        nChars++;
        if (nChars == charsToCursor) {
          console.log("Setting cursor position to", i + 1);
          _textArea.selectionStart = i + 1;
          _textArea.selectionEnd = i + 1;
          break;
        }
      }
    }
  };

  // This sets cursor position when value is updated
  useEffect(() => {
    console.log("Calling setCursorPos", charsToCursor);
    setCursorPos(value);
  }, [value]);

  const onChangeHandler = (e) => {
    e.preventDefault();
    let str = e.target.value;
    try {
      let _pos = str.substring(0, inputEl.current.selectionEnd + 1).replace(/ /g, "").length;
      // Get the valid string
      let validStr = JSON.stringify(JSON.parse(str), undefined, 4);
      //console.log("String is valid JSON", validStr)
      // Set the string
      console.log("Set characters before cursor", _pos);
      setCharsToCursor(_pos);
      setIsValid(true);
      setValue(validStr);
    } catch (e) {
      //console.log("Invalid JSON", str, e)
      setIsValid(false);
      setValue(str);
    }
  };

  return (
    <React.Fragment>
      <textarea
        ref={inputEl}
        className="form-control text-monospace"
        rows="20"
        onChange={onChangeHandler}
        value={value}
      />
      {!isValid ? (
        <small className="text-danger">
          <b>Enter valid json</b>
        </small>
      ) : null}
      <button
        className="btn btn-sm btn-primary pl-2 pr-2 m-1 p-0 float-right"
        disabled={!isValid}
        onClick={(e) => {
          e.preventDefault();
          let _value = JSON.parse(value);
          console.log("Setting assignments to document meta_json", _value);
          setNodeMeta({
            trackerId,
            refId,
            commitId,
            hideModal,
            setPending,
            path: document.get("_path"),
            meta_json: { assignments: _value },
          });
        }}
      >
        {pending ? <div className="spinner-border spinner-border-sm" /> : "Save"}
      </button>
    </React.Fragment>
  );
}

function SetAssignmentsModal(props) {
  let { hideModal } = useModal();
  return (
    <Modal title="Set Assignments for Form Data">
      <SetAssignmentInput {...props} hideModal={hideModal} />
    </Modal>
  );
}

export function RequiredStar({ document }) {
  let isRequired = document?.getIn(["meta_json", "isRequired"], false);
  return isRequired ? <span style={{ color: "red" }}>*</span> : null;
}

export default function DocumentRow(props) {
  let { path, indentLevel, bgColor } = props;
  let { getNode, getChildren, fetches } = useProjectContext();
  let ids = useFullIds();
  let { setModal } = useModal();

  let document = getNode(path);
  let description = getChildren(document)
    .filter((i) => i.get("_type") == "trackerdescription")
    .first();

  let name = description?.get("title") || document.get("name").substring(0, 8);
  let showHistory = document.getIn(["meta_json", "showHistory"]) === false ? false : true;
  let hideNA = document.getIn(["meta_json", "hideNA"]);

  let menuProps = { ...props, ...ids, document, description, fetches };

  return (
    <div className={`row ${bgColor}`} style={{ borderTop: "1px solid #ddd" }}>
      <div className="col-sm-11">
        <div style={{ margin: "0", marginLeft: `${(indentLevel + 1) * 1.5}em` }}>
          <Octicon name="file" />
          {name}
          <RequiredStar document={document} />
          <Tooltip
            animateFill={false}
            html={
              <div className="text-left">
                <div dangerouslySetInnerHTML={{ __html: description?.get("text") }} />
                <p style={{ marginTop: "1rem" }}>Doc id: {document?.get("uid")?.substring(0, 8)}</p>
              </div>
            }
          >
            <Octicon name="info" className="ml-2" />
          </Tooltip>
        </div>
      </div>
      <div className="col-sm-1 m-0 p-0">
        <BSBtnDropdown
          links={[
            {
              name: "Edit",
              onClick: () => setModal(<EditDescriptionModal {...menuProps} />),
            },
            {},
            {
              name: "Setup Form Fields",
              onClick: () => setModal(<DocumentMetaModal {...menuProps} />),
            },
            {
              name: "Set Assignments",
              onClick: () => setModal(<SetAssignmentsModal {...menuProps} />),
            },
            {},
            {
              name: "Toggle Required",
              onClick: () => {
                setNodeMeta({
                  ...menuProps,
                  setPending: () => {},
                  meta_json: {
                    isRequired: !document.getIn(["meta_json", "isRequired"]),
                  },
                });
              },
            },
            {
              name: `${hideNA ? "Show" : "Hide"} N/A`,
              onClick: () => {
                setNodeMeta({
                  ...menuProps,
                  setPending: () => {},
                  meta_json: {
                    hideNA: !hideNA,
                  },
                });
              },
            },
            {
              name: `${showHistory ? "Hide" : "Show"} History by default`,
              onClick: () => {
                setNodeMeta({
                  ...menuProps,
                  setPending: () => {},
                  meta_json: {
                    showHistory: !showHistory,
                  },
                });
              },
            },
            {},
            {
              name: "Delete",
              onClick: (e) => {
                deleteNode({ ...menuProps });
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
