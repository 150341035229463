import React, { useState } from "react";
import { ApproveForm, getCommitStatus } from "AppSrc/project/commits/row";
import { BSBtn } from "traec-react/utils/bootstrap";
import { Redirect } from "react-router-dom";
import { useProjectPermissions } from "../utils/hooks";
import { useReportContext } from "../report/context";
import { useFullIds } from "../utils/hooks";

export function ApproveRejectForm({ currentPeriodString }) {
  let { projectId, refId, crefId } = useFullIds();
  let { commit } = useReportContext();
  let { isApprover } = useProjectPermissions();
  let [showForm, setShowForm] = useState(false);
  let [status, setStatus] = useState(null);
  let [comment, setComment] = useState("");
  let commitStatus = getCommitStatus(commit);

  let props = {
    projectId,
    commit,
    refId,
    crefId,
    currentPeriodString,
    showForm,
    setShowForm,
    status,
    setStatus,
    comment,
    setComment,
  };

  console.log("ApproveRejectForm", showForm, commitStatus === "Pending Approval");
  const approve_statuses = new Set(["Pending Approval", "Pending Review"]);
  if (isApprover(commit)) {
    if (showForm && !approve_statuses.has(commitStatus)) {
      return <Redirect to={`/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/evals`} />;
    }

    if (approve_statuses.has(commitStatus)) {
      return showForm ? <ApproveForm {...props} /> : <ApproveRejectButton {...props} />;
    }
  }

  return null;
}

export function ApproveRejectButton({ setStatus, setShowForm }) {
  return (
    <React.Fragment>
      <div className="m-2">
        <BSBtn
          text={"Approve report"}
          extra_className="ml-3"
          onClick={(e) => {
            setStatus("APPROVE");
            setShowForm(true);
          }}
        />
      </div>
      <div className="m-2">
        <BSBtn
          text={"Reject report"}
          onClick={(e) => {
            setStatus("REJECT");
            setShowForm(true);
          }}
        />
      </div>
    </React.Fragment>
  );
}

export function DisplayStatus({ currentPeriodString, status }) {
  return (
    <div>
      <p>
        Reporting period <b>{currentPeriodString}</b> is <b>{status}</b>
      </p>
    </div>
  );
}
