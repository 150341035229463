import React from "react";
import { ErrorBoundary } from "traec-react/errors";
import Category from "./categoryRow";
import Metric from "./metricRow";
import Document from "./documentRow";
import { useProjectContext } from "../context";
import Im from "immutable";

/* 
Component for rendering whatever node type is at a row
*/

export const listToMap = (data, key) => {
  return data.reduce((a, c) => a.set(c.get(key), c), Im.Map());
};

export const mergeNodes = (data, newData, byKey = "path") => {
  let nodesByPath = listToMap(data, byKey);
  let newNodesByPath = listToMap(
    Im.fromJS(newData)?.filter((i) => i.get(byKey)),
    byKey
  );
  return nodesByPath?.merge(newNodesByPath)?.toList();
};

function ComponentNotFound({ path, type }) {
  return (
    <div className="row">
      <div className="col-sm-12">
        Component not found for node of type {type} at path {path}
      </div>
    </div>
  );
}

function Node(props) {
  let { node, showOnly, componentMap, indentLevel = 0 } = props;
  let _path = node.get("_path");
  let _type = node.get("_type");

  // If we have showOnly passed as a prop then only render children that are in showOnly
  if (showOnly !== undefined && showOnly[_path] == undefined) {
    return null;
  }

  // This can be taken in props or not
  const COMPONENT_MAP = componentMap || {
    metricscore: Metric,
    tree: Category,
    document: Document,
  };
  let Component = COMPONENT_MAP[_type];

  // If the node type does not match a valid type that we have a component for then render a message
  if (!Component) {
    return null;
    //return <ComponentNotFound key={i} path={_path} type={_type} />;
  }

  // Render the sub-component in an ErrorBoundary
  return (
    <ErrorBoundary
      title={null}
      className="row alert alert-warning pt-0 pb-0 mt-1 mb-0"
      msg={
        <span>
          Error rendering row for {_type} at {_path}
        </span>
      }
    >
      <Component
        {...props}
        indentLevel={indentLevel + 1}
        path={_path}
        hidden={showOnly ? !showOnly[_path] : undefined}
      />
    </ErrorBoundary>
  );
}

function SortAndRender(props) {
  let { sortKey, nodes } = props;
  return nodes.sortBy((i) => i.get(sortKey) || i.get("name")).map((node, i) => <Node key={i} {...props} node={node} />);
}

export function SubNodes(props) {
  let { getNode, getChildren, commit, getMetricById } = useProjectContext();
  let { hide, path } = props;

  if (hide) return null;

  let sortKey = commit?.getIn(["meta_json", "sortKey"]);

  // Get the node
  let node = getNode(path);

  // Get the immediate children of this node
  let children = getChildren(node);

  // Get the additional children of this node
  let extraChildren = (node?.getIn(["meta_json", "additional_children"]) || Im.List())
    .map((i) => getMetricById(i))
    .map((i) =>
      i
        .getIn(["node", i.get("type")])
        .set("_path", i.get("path"))
        .set("_type", i.get("type"))
    );

  console.log("SubNodes extraChildren", path, extraChildren?.toJS());

  return (
    <>
      <SortAndRender {...props} nodes={children} sortKey={sortKey} parentPath={null} />
      <div className="m-0 p-0 text-muted">
        <SortAndRender {...props} nodes={extraChildren} sortKey={sortKey} disabled={true} parentPath={path} />
      </div>
    </>
  );
}
