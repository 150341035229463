import React from "react";
import Traec from "traec";
import { Link } from "react-router-dom";
import { BSBtnDropdown, BSModal } from "traec-react/utils/bootstrap/";
import BaseFormConnected from "traec-react/utils/form";
import { counter } from "./index";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { ErrorBoundary } from "traec-react/errors";
import { workPackageFields } from "./form";
import { getTerm } from "../../tree/utils";
import { useFullIds } from "../utils/hooks";
import useApi from "storybook-dashboard/utils/fetching";
import Im from "immutable";
import { setNewWorkPackageFields } from "../../tree/utils";

const deleteWorkPackage = (e, props) => {
  e.preventDefault();
  let { commitId, rootRefId: refId, trackerId, rootRef } = props;
  let refName = rootRef?.get("name");
  console.log("deleteWorkPackage", trackerId, refId, commitId, refName);
  if (!trackerId | !refId | !commitId) return null;
  confirmDelete({
    text: `This will delete the Reporting Package: ${refName}.\n\n Are you sure you would like to proceed?`,
    onConfirm: () => {
      new Traec.Fetch("tracker_ref", "delete", { trackerId, refId, commitId }).dispatch();
    },
  });
};

function SubRefs(props) {
  let { projectId, tracker, cref, subRefs, showMenu, menuOnSelectedOnly } = props;

  if (!subRefs?.size) return null;

  return subRefs
    .filter((i) => i)
    .map((item, i) => (
      <WorkPackageRow
        key={i}
        rootRef={item}
        projectId={projectId}
        tracker={tracker}
        cref={cref}
        showMenu={showMenu}
        menuOnSelectedOnly={menuOnSelectedOnly}
      />
    ));
}

function WPDropDownMenu(props) {
  let { showMenu, projectId, trackerId, rootRefId: refId, commitId, treeId, hideDelete } = props;
  let { data: projectReportingPeriods } = useApi("/api/project/{projectId}/reporting_periods/", { projectId });
  let { data: disciplines } = useApi("/api/project/{projectId}/discipline/", { projectId });

  if (!showMenu) {
    return null;
  }

  let modalId = `${refId}-modal`;

  // Get the fetch for adding a new sub-work package
  let fetch = new Traec.Fetch("tracker_ref_tree_branch", "post", {
    trackerId,
    refId,
    commitId,
    treeId,
    skip_categories: true,
  });
  fetch.updateFetchParams({
    preFetchHook: (body) => {
      let _body = {
        ...body,
        ref_name: body.name || "master",
        from_commit: commitId,
      };
      console.log("CREATING REF WITH PARAMETERS", _body);
      return _body;
    },
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    },
  });

  let links = [
    {
      name: "Add a sub-Reporting Package",
      onClick: (e) => {
        $(`#${modalId}`).modal("show");
      },
    },
    {
      name: "Settings",
      linkTo: `/project/${projectId?.substring(0, 8)}/wpack/${refId?.substring(0, 8)}/details`,
    },
  ];
  if (!hideDelete) {
    links = links.concat([{}, { name: "Delete", onClick: (e) => deleteWorkPackage(e, props) }]);
  }

  return (
    <div className="sidebar-dropdown-text col-sm-1 m-0 p-0">
      <BSBtnDropdown links={links} header={" "} />
      <BSModal
        id={modalId}
        title={getTerm("Add a Reporting Package", props)}
        body={
          <BaseFormConnected
            params={fetch.params}
            fields={setNewWorkPackageFields(workPackageFields, disciplines, projectReportingPeriods)}
            forceShowForm={true}
            hideUnderline={true}
          />
        }
      />
    </div>
  );
}

const getSubRefs = ({ commitId, branches }) => {
  return branches.filter((i) => i.get("commit") == commitId).map((i) => i.getIn(["target", "ref"]));
};

export default function WorkPackageRow(props) {
  let {
    showMenu,
    rootRef,
    rootMasterRefId,
    selectedRefId,
    includeRoot = true,
    alternateBgColor = true,
    menuOnSelectedOnly = false,
  } = props;

  let { projectId, trackerId } = useFullIds();
  let commitId = rootRef.getIn(["latest_commit", "uid"]);
  let rootTreeId = rootRef.getIn(["latest_commit", "tree_root", "uid"]);

  let refId = rootRef.get("uid");
  let _projectId = projectId.substring(0, 8);
  let _refId = refId.substring(0, 8);

  let { data: branches } = useApi("/api/tracker/{trackerId}/branch/", { trackerId });

  if (!branches?.size) return null;

  let subRefs = getSubRefs({ commitId, branches });

  if (!rootRef) return null;
  let rootTreeName = rootRef?.get("name");
  if (rootTreeName == "conversion_factors") return null; // Deprecated special branch (no longer used but here just in case)

  let isRoot = refId == rootMasterRefId;
  let isCurrent = refId == selectedRefId;

  rootTreeName = isRoot ? <b>Project Home</b> : rootTreeName;
  let bgColor = isCurrent ? "bg-info" : "";
  let linkLoc = isRoot ? `/project/${_projectId}` : `/project/${_projectId}/wpack/${_refId}`;

  let rowNum = counter.row++;
  let styleObj = showMenu && alternateBgColor ? { backgroundColor: (rowNum + 1) % 2 && !bgColor ? "#ddd" : "" } : {};
  styleObj = {};

  let shouldRender = rootRef.get("project") === projectId;

  let doRender = true;
  if (!includeRoot && isRoot) {
    doRender = false;
  }

  // Whether we should show the dropdown menu
  let _showMenu = showMenu;
  if (menuOnSelectedOnly) {
    _showMenu = isCurrent;
  }

  return (
    <ErrorBoundary>
      <div className={"ml-2"}>
        {shouldRender && doRender ? (
          <div className={`row m-0 p-0 ${bgColor}`} style={styleObj}>
            <div key={refId} className={`${showMenu ? "col-sm-11" : "col-sm-12"} m-0 p-0`}>
              <Link to={linkLoc}>
                <p className={`m-0 p-0`}>{shouldRender ? rootTreeName : null}</p>
              </Link>
            </div>
            {_showMenu ? (
              <WPDropDownMenu
                {...{ ...props, projectId, trackerId, rootRefId: refId, refId, commitId, treeId: rootTreeId }}
              />
            ) : null}
          </div>
        ) : null}

        <SubRefs {...props} subRefs={subRefs} />
      </div>
    </ErrorBoundary>
  );
}
