import React, { useState } from "react";
import Octicon from "react-octicon";
import { DropzoneButton } from "AppSrc/utils/dropZone/dropZoneHooks";
import { Tooltip } from "react-tippy";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import DocumentHistoryRows from "./documentHistoryRows";
import { ReportDocumentFormButton } from "./reportDocumentForm";
import { ReportDocumentVerticalFormButton } from "./reportDocumentFormVertical";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { deleteDocumentObject } from "./documentHistoryRows";
import { RequiredStar } from "AppSrc/project/metrics/documentRow";
import { Hider } from "storybook-dashboard/utils";
import Im from "immutable";
import useApi, { doFetch, fetcher } from "storybook-dashboard/utils/fetching";
import { useProjectContext } from "../context";
import { useFullIds, useProjectPermissions } from "../utils/hooks";
import { mergeNodes } from "../metrics/node";

export function DocumentTitleTooltip({ doc, indentLevel, isProjectAdmin }) {
  let title = doc ? doc.getIn(["description", "title"]) || doc.get("name").substring(0, 8) : null;

  let descr = doc.getIn(["description", "text"]) || "";
  let toolTipHtml = (
    <div className="text-left">
      <div dangerouslySetInnerHTML={{ __html: descr.trim() }} />
      {isProjectAdmin ? <p style={{ marginTop: "1rem" }}>Doc id: {doc?.get("uid")?.substring(0, 8)}</p> : null}
    </div>
  );

  return (
    <div style={{ margin: "0", marginLeft: `${(indentLevel + 1) * 1.5}em` }}>
      {title}
      <RequiredStar document={doc} />
      <Tooltip animateFill={false} html={toolTipHtml}>
        <Octicon name="info" className="ml-2" />
      </Tooltip>
    </div>
  );
}

export const downloadS3Object = ({ trackerId, commitId, docId, currentDocObject }) => {
  let docObjectId = currentDocObject.get("uid");

  fetcher(`/api/tracker/${trackerId}/commit/${commitId}/document/${docId}/object/${docObjectId}/?url=true`, "GET")
    .then(({ payload }) => {
      let signed_url = payload.get("signed_url");
      if (signed_url) {
        console.log("Downloading file", signed_url);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = signed_url;
        a.download = payload.get("filename") || "unknown.file";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
    .catch((err) => {
      failureHandler ? failureHandler(err) : null;
    });
};

export function CurrentObject(props) {
  let { currentDocObject, errors, status, hide, noFileMessage } = props;
  if (hide) {
    return null;
  }

  if (!currentDocObject?.get("uid")) {
    return <span className="float-left text-muted">{noFileMessage ? noFileMessage : `No file`}</span>;
  }

  if (status && status === "sent") {
    return <span className="alert-warning float-left">Uploading... please wait.</span>;
  }

  if (errors || status === "failed") {
    console.log("ERRORS IN DOCUMENT UPLOAD", errors);
    if (errors === "Request Entity Too Large") {
      errors = "Document too large! Maximum allowed upload size is 500Mb";
    } else {
      errors = "Error in upload.  Maximum allowed upload size is 500Mb";
    }
    return <span className="alert-danger float-left">{errors}</span>;
  }

  let filename = currentDocObject ? currentDocObject.get("filename") : null;
  let url = currentDocObject ? currentDocObject.get("signed_url") : null;

  let isUploaded = currentDocObject ? !(currentDocObject.created && currentDocObject.virus_checked) : false;

  return (
    <ErrorBoundary>
      {isUploaded ? <Octicon name="check" className="float-left" /> : <Octicon name="file" />}

      <a
        style={{ cursor: "pointer", color: "#007bff", textDecoration: "underline" }}
        className="link-primary"
        onClick={(e) => {
          e.preventDefault();
          downloadS3Object({ ...props });
        }}
      >
        {filename}
      </a>
    </ErrorBoundary>
  );
}

export const getPtr = ({ commitId, refId }) => ({
  ptrId: commitId || refId,
  ptr: commitId ? "commit" : "ref",
});

const updateDocStatus = ({ trackerId, commitId, refId, path, statusName, triggerObjectList, fetches }) => {
  let { ptr, ptrId } = getPtr({ commitId, refId });
  doFetch(`/api/tracker/${trackerId}/${ptr}/${ptrId}/node/${path}/`, "PUT", {
    type: "document",
    node: {
      document: {
        status: {
          status: { name: statusName },
        },
      },
    },
  })
    .then((r) => r.json())
    .then((newData) => {
      let { data, mutate } = fetches?.nodes;
      mutate(mergeNodes(data, newData));
    });
};

export function DocumentNoReportCheckbox(props) {
  let { path: pathId, docStatus, setNoReport } = props;
  return (
    <input
      type="checkbox"
      disabled={false}
      className={`m-0 p-0`}
      defaultChecked={docStatus?.getIn(["status", "name"]) == "Not for Submission"}
      onChange={(e) => {
        let statusName = e.target.checked ? "Not for Submission" : "OK for Submission";
        console.log("CHECKED NO REPORT for document at path", pathId, docStatus?.toJS());
        console.log("Changing document status to: ", statusName);
        updateDocStatus({ ...props, statusName });
        setNoReport(e.target.checked);
      }}
      name={"noreport"}
    />
  );
}

function SelectFile(props) {
  let {
    hide,
    doc,
    disableInputs,
    selectedFiles,
    onDrop,
    onCancelUpload,
    onConfirmHandler,
    isUploadingFile,
    startIsUploadingFile,
    stopIsUploadingFile,
  } = props;

  if (hide || disableInputs) {
    return null;
  }

  let files = selectedFiles.map((file) => (
    <a key={file.name}>
      Upload {file.name}? ({(file.size / 1e6).toFixed(1)}Mb)
    </a>
  ));

  // Give a warning if the file is too large
  let confirmButton = null;
  if (selectedFiles.filter((file) => file.size / 1e6 > 500).length) {
    files = [
      <span key={0} className="alert-danger">
        Maximum allowed upload size is 500Mb
      </span>,
    ];
  } else {
    confirmButton = (
      <BSBtn text={"Upload"} onClick={onConfirmHandler} extra_className="pl-1 pr-1 m-0 p-0" noFloatRight={true} />
    );
  }

  return (
    <div className="float-right">
      <DropzoneButton
        onDrop={onDrop}
        extra_className="pl-1 pr-1 m-0 p-0"
        selectAreaText="Select file"
        confirmButton={confirmButton}
        selectedFiles={files}
        onCancelUpload={onCancelUpload}
        isUploadingFile={isUploadingFile}
        startIsUploadingFile={startIsUploadingFile}
        stopIsUploadingFile={stopIsUploadingFile}
      />
    </div>
  );
}

const uploadFile = (props) => {
  let { trackerId, commitId, path, selectedFiles, setSelectedFiles, triggerObjectList, fetches } = props;

  // Add the file object to the form and dispatch
  let formData = new FormData();
  formData.append("fileobj", selectedFiles[0]);
  formData.append("type", "document");
  formData.append("path", path);

  // Reset the state
  setSelectedFiles([]);
  //  setUploadedFile(selectedFiles[0].name);

  doFetch(`/api/tracker/${trackerId}/commit/${commitId}/node/${path}/`, "PUT", formData)
    .then((r) => r.json())
    .then((newData) => {
      let { data, mutate } = fetches.nodes;
      mutate(mergeNodes(data, newData));
      triggerObjectList();
    });
};

const clearFile = (props) => {
  let { trackerId, commitId, refId, docStatus, fetches } = props;
  confirmDelete({
    text: `This will clear the current uploaded document.  It will still appear in the upload history.  Would you like to proceed?`,
    onConfirm: () => {
      let path = docStatus.get("_path");
      let { ptr, ptrId } = getPtr({ commitId, refId });
      doFetch(`/api/tracker/${trackerId}/${ptr}/${ptrId}/node/${path}/`, "PATCH", {
        type: "documentstatus",
        node: {
          documentstatus: {
            current_object: null,
          },
        },
      })
        .then((r) => r.json())
        .then((newData) => {
          let { data, mutate } = fetches.nodes;
          mutate(mergeNodes(data, newData));
        });
    },
  });
};

const getByType = (nodes, _type) => {
  return nodes.filter((i) => i.get("_type") == _type)?.first();
};

export default function ReportDocumentRow(props) {
  let { trackerId, refId, commitId } = useFullIds();
  let { getNode, getChildren, fetches, commit } = useProjectContext();
  let { permissions } = useProjectPermissions();
  let { rowColor, indentLevel, path, disableInputs } = props;

  let [selectedFiles, setSelectedFiles] = useState([]);
  let [uploadedFile, setUploadedFile] = useState(false);
  let [isUploadingFile, setIsUploadingFile] = useState(false);
  let [showHistory, setShowHistory] = useState(doc?.getIn(["meta_json", "showHistory"]) === false);

  let isProjectAdmin = permissions?.get("isAdmin");

  let doc = getNode(path);
  let description = getByType(getChildren(doc), "trackerdescription");
  let docStatus = getByType(getChildren(doc), "documentstatus");
  let currentDocObject = docStatus?.get(`current_object`, null);

  doc = doc.set("description", description);

  let docId = doc?.get("uid");
  let { data: docObjects, trigger: triggerObjectList } = useApi(
    "/api/tracker/{trackerId}/commit/{commitId}/document/{docId}/object/?thisCommitOnly=true",
    { trackerId, commitId, docId }
  );

  let [noReport, setNoReport] = useState(docStatus?.getIn(["status", "name"]) == "Not for Submission");
  let isForm = doc?.getIn(["meta_json", "input_type"]) == "form";
  let isFormVertical = doc?.getIn(["meta_json", "input_type"]) == "formvertical";

  let _props = {
    ...props,
    commit,
    doc,
    docStatus,
    currentDocObject,
    selectedFiles,
    setSelectedFiles,
    setNoReport,
    trackerId,
    commitId,
    docId,
    triggerObjectList,
    fetches,
    readOnly: disableInputs,
  };

  if (isFormVertical) {
    return <ReportDocumentVerticalFormButton {..._props} />;
  }

  if (isForm) {
    return <ReportDocumentFormButton {..._props} />;
  }

  return (
    <React.Fragment>
      <tr style={{ backgroundColor: rowColor }}>
        <td className="border-0">
          <DocumentTitleTooltip doc={doc} indentLevel={indentLevel} isProjectAdmin={isProjectAdmin} />
        </td>

        <td colSpan={3} className="border-0 pr-3">
          <CurrentObject {..._props} hide={noReport} />
          <SelectFile
            {...props}
            hide={noReport}
            selectedFiles={selectedFiles}
            onConfirmHandler={(e) => {
              if (selectedFiles) {
                uploadFile({ ..._props });
              }
            }}
            onDrop={(files) => {
              setSelectedFiles(files);
              setIsUploadingFile(false);
            }}
            onCancelUpload={() => {
              setSelectedFiles([]);
              setIsUploadingFile(false);
            }}
            isUploadingFile={isUploadingFile}
            startIsUploadingFile={() => setIsUploadingFile(true)}
            stopIsUploadingFile={() => setIsUploadingFile(false)}
          />
        </td>
        <td className="text-center border-0">
          <DocumentNoReportCheckbox
            {..._props}
            onChange={(e) => {
              setSelectedFiles([]);
              setIsUploadingFile(false);
              setNoReport(true);
            }}
          />
        </td>
        <td className="border-0">
          <BSBtnDropdown
            links={[
              {
                name: `${showHistory ? "Hide" : "Show"} History`,
                onClick: (e) => setShowHistory(!showHistory),
              },
              currentDocObject
                ? {
                    name: `Clear current upload`,
                    onClick: (e) => clearFile({ fetches, trackerId, refId, commitId, docStatus }),
                  }
                : {},
            ]}
          />
        </td>
      </tr>
      {showHistory ? <DocumentHistoryRows {..._props} hide={!showHistory} indentLevel={indentLevel + 1} /> : null}
    </React.Fragment>
  );
}
