import React, { useState } from "react";
import Traec from "traec";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import Octicon from "react-octicon";

import BaseFormConnected from "traec-react/utils/form";
import { BSForm } from "traec-react/utils/bootstrap";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { metricFields, frequencyFields } from "./forms";
import { metricCounters } from "./index";

import { SubNodes } from "./node";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { copyToCommitHandler } from "./utils";
import { doFetch, fetcher } from "storybook-dashboard/utils/fetching";
import { Modal, useModal } from "storybook-dashboard/components/modal";

import { SetNodeMetaJsonInput, MetricFrequency } from "./metricRow";

import { useFullIds } from "../utils/hooks";
import { useProjectContext } from "../context";
import { DragDropHandle } from "./dragHandle";
import { mergeNodes } from "./node";

const MODAL_ID = "CommonTreeModal001";

function AdminMenu(props) {
  let ids = useFullIds();
  let { fetches } = useProjectContext();
  let { setModal } = useModal();

  let { copyToCommit } = props;
  if (copyToCommit) {
    return (
      <a
        className="btn btn-sm btn-default m-0 p-0"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          copyToCommitHandler(props);
        }}
      >
        Add
      </a>
    );
  }

  return (
    <BSBtnDropdown
      header={<Octicon name="gear" />}
      links={[
        {
          name: "Add Metric",
          onClick: (e) => setModal(<AddMetricModal {...props} {...ids} {...{ fetches }} />),
        },
        {
          name: "Edit Issue Name",
          onClick: () => setModal(<EditTreeModal {...props} {...ids} {...{ fetches }} />),
        },
        {
          name: "Set Frequency",
          onClick: () => setModal(<EditFrequencyModal {...props} {...ids} {...{ fetches }} />),
        },
        {
          name: "Edit raw meta-data",
          onClick: () => setModal(<EditMetaJsonModal {...props} {...ids} {...{ fetches }} />),
        },
        { name: null },
        { name: "Delete", onClick: (e) => deleteTree({ ...props, fetches }) },
      ]}
    />
  );
}

const lsKey = (path = "", refId = "") => {
  let key = `${refId.substring(0, 8)}-${(path || "").substring(7)}`;
  return key;
};

function AddMetricModal(props) {
  let { trackerId, refId, commitId, path, categoryName, fetches } = props;
  let { hideModal } = useModal();

  let { data, mutate } = fetches.nodes;

  let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, refId, commitId, path });
  fetch.updateFetchParams({
    preFetchHook: (body) => ({
      type: "metricscore",
      path,
      node: {
        metricscore: {
          metric: {
            ...body,
            category: categoryName,
          },
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Add a metric">
      <BaseFormConnected params={fetch.params} fields={metricFields} forceShowForm={true} hideUnderline={true} />
    </Modal>
  );
}

const deleteTree = (props) => {
  let { tree, path, fetches } = props;
  let treeName = tree ? tree.get("name") : null;

  confirmDelete({
    text: `This will delete the Category: ${treeName} including any sub-metrics and documents contained within.  Are you sure you would like to proceed?`,
    onConfirm: () => {
      let { url, mutate, data, payload } = fetches?.nodes;
      doFetch(`${url}${path}/`, "DELETE").then((res) => {
        if (res.ok) {
          mutate(data?.filter((i) => !i.get("path").startsWith(path)));
        }
      });
    },
  });
};

function EditTreeModal(props) {
  let { tree, trackerId, refId, commitId, path: pathId, fetches } = props;
  let { hideModal } = useModal();

  let { data, mutate } = fetches.nodes;

  let fetch = new Traec.Fetch("tracker_node", "put", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      type: "tree",
      node: {
        tree: data,
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Edit issue name">
      <BaseFormConnected
        params={fetch.params}
        fields={{ name: { value: "", endRow: true } }} // This is converted to Immutable when Modal props are stored in Redux
        initFields={tree}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

const EditFrequencyModal = (props) => {
  let { tree, trackerId, refId, commitId, path: pathId, fetches } = props;
  let { hideModal } = useModal();

  let { data, mutate } = fetches.nodes;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      type: "tree",
      node: {
        tree: {
          meta_json: {
            freq_num: data.freq_num || null,
            freq_unit: data.freq_unit || null,
            from_date: data.from_date || null,
          },
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Edit Reporting Frequency">
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(frequencyFields).toJS()}
        initFields={tree.get("meta_json")}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
};

function EditMetaJsonModal(props) {
  let { hideModal } = useModal();
  let { tree } = props;
  return (
    <Modal title="Edit node meta-data">
      <SetNodeMetaJsonInput {...props} node={tree} nodeType={"tree"} hideModal={hideModal} />
    </Modal>
  );
}

const copyTreeToCommit = () => {
  e.preventDefault();
  let { commitId: fromCommitId, copyToCommit, parentTreeId, rootTreeId } = this.props;

  // Get the tracker and commit ID to the new commit
  let { trackerId, commitId } = copyToCommit;

  let fetch = new Traec.Fetch("tracker_commit_edge", "put", { trackerId, commitId });
  fetch.updateFetchParams({
    body: {
      edge_type: "treetree",
      parent_id: parentTreeId,
      child_id: rootTreeId,
      from_commit: fromCommitId,
    },
  });

  fetch.dispatch();
};

export default function CategoryRow(props) {
  let { path } = props;
  let [collapsed, setCollapsed] = useState(false);
  let { trackerId } = useFullIds();
  let { getNode, commit } = useProjectContext();

  let refId = commit?.get("ref");
  let node = getNode(path);

  metricCounters.row = 0;
  let caret = collapsed ? "triangle-right" : "triangle-down";

  return (
    <ErrorBoundary>
      <div className="row" style={{ borderTop: "1px solid #ddd" }}>
        <div className="col-sm-6">
          <DragDropHandle trackerId={trackerId} path={path} />
          <span
            onClick={(e) => {
              let key = lsKey(path, refId);
              localStorage.setItem(key, !collapsed);
              console.log("Set localStorage key", key, !collapsed);
            }}
          >
            <Octicon className="expand_caret" name={caret} />
            <b>{node.get("name")}</b>
          </span>
        </div>
        <div className="col-sm-3">
          <MetricFrequency score={node.get("meta_json")} />
        </div>
        <div className="col-sm-2"></div>
        <div className="col-sm-1 m-0 p-0">
          <AdminMenu {...props} tree={node} onAddClick={() => copyTreeToCommit()} />
        </div>
      </div>
      <SubNodes path={path} hide={collapsed} />
    </ErrorBoundary>
  );
}
